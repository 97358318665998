.sidebar {
    flex: 1;
    border-right: 0.5px solid #e6e3e3;
    min-height: 100vh;
    background-color: white;
  }
  .sidebar .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar .top .logo {
    font-size: 16px;
    font-weight: bold;
    color: #4d728c;
  }
  .sidebar hr {
    height: 0;
    border: 0.5px solid #e6e3e3;
  }
  .sidebar .center {
    padding-left: 10px;
  }
  .sidebar .center ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .sidebar .center ul .title {
    font-size: 10px;
    font-weight: bold;
    color: #999;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .sidebar .center ul li {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }
  .sidebar .center ul li:hover {
    background-color: #ece8ff;
  }
  .sidebar .center ul li .icon {
    font-size: 18px;
    color: #4d728c;
  }
  .sidebar .center ul li span {
    font-size: 16px;
    font-weight: 600;
    color: #888;
    margin-left: 10px;
  }
  .sidebar .bottom {
    display: flex;
    align-items: center;
    margin: 10px;
  }
  .sidebar .bottom .colorOption {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #4d728c;
    cursor: pointer;
    margin: 5px;
  }
  .sidebar .bottom .colorOption:nth-child(1) {
    background-color: whitesmoke;
  }
  .sidebar .bottom .colorOption:nth-child(2) {
    background-color: #333;
  }
  .sidebar .bottom .colorOption:nth-child(3) {
    background-color: darkblue;
  }

  .number_pending{
    background-color: #f7e3b3;
  }
  .number_delivered{
    background-color: #9dcd3c;
  }
  .number_submitted{
    background-color: #1f79be;
  }
  .border_radius{
    border-radius: 0.50rem!important;
  }
  .total_registered{
    background-color: #53e9ff;
  }