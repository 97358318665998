/* .footer-bs {
    background-color: #3c3d41;
    padding: 40px 40px;
    color: rgba(255,255,255,1.00);
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 6px;
}
.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { padding:10px 25px; }
.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-color: transparent; }

.footer-bs .footer-nav ul.pages { list-style:none; padding:0px; }
.footer-bs .footer-nav ul.pages li { padding:5px 0px;}
.footer-bs .footer-nav ul.pages a { color:rgba(255,255,255,1.00); font-weight:bold; text-transform:uppercase; }
.footer-bs .footer-nav ul.pages a:hover { color:rgba(255,255,255,0.80); text-decoration:none; }
.footer-bs .footer-nav p {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom:10px;
    font-weight: bold;
}

.footer-bs .footer-nav ul.list { list-style:none; padding:0px; }
.footer-bs .footer-nav ul.list li { padding:5px 0px;}
.footer-bs .footer-nav ul.list a { color:rgba(255,255,255,1); }
.footer-bs .footer-nav ul.list a:hover { color:rgba(255,255,255,1); }

.footer-bs .footer-social ul { list-style:none; padding:0px; }
.footer-bs .footer-social h4 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.footer-bs .footer-social li { padding:5px 4px;}
.footer-bs .footer-social a { color:rgba(255,255,255,1.00);}
.footer-bs .footer-social a:hover { color:rgba(255,255,255,0.80); text-decoration:none; }

.footer-bs .footer-ns h4 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom:10px;
}
.footer-bs .footer-ns p { font-size:12px; color:rgba(255,255,255,0.70); } */
/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/
.custom-header {
  background: #0d3c61 !important;
  font-size: 15px;
}
.dropdown-menu {
  background-color: #1e5885 !important;
}
.dropdown-menu .nav-link {
  padding: 8px !important;
}
.dropdown-menu .nav-link:hover {
  background-color: #0d3d619f !important;
  color: white !important;
}
.nav-link.active {
  background-color: #40849f !important;
  font-weight: bold;
  border-radius: 6px;
  padding: 8px !important;
}
.nav-link:hover {
  font-weight: 700 !important;
}
.custom-header a {
  text-decoration: none !important;
  padding: 0 !important;
  display: block;
  text-align: left;
  color: white;
}
