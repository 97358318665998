.text_bold_count {
  font-weight: bold;
}
.desc_size {
  font-size: "16px !important";
}

.moving-text:hover {
  animation-play-state: paused;
  cursor: pointer;

  /* overflow-y: hidden; */
}

/* The animation */
@keyframes marquee {
  100% {
    transform: translateY(-100%);
  }
  /* 30%{transform: translateY(-100%);} */
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text {
    animation: marquee 10s linear infinite;
  }
}

.large_text {
  text-overflow: ellipsis;
  overflow: hidden;
  /* // Addition lines for 2 line or multiline ellipsis */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.male_card_color {
  background-color: #8ac7db;
}

.marque_section {
  overflow-y: hidden;
}

.height_adjust {
  max-height: 150px;
}
