.App {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
  table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
    overflow-y: scroll;
  }
    
  th {
    border-bottom: 1px solid black;
  }
    
  td {
    text-align: center;
  }