* {
  margin: 0;
  padding: 0;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.test {
  color: brown;
}

a {
  text-decoration: none !important;
  text-align: center;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  color: white !important;
}

/* Theme style */
.globalnav-bg,
.footer-bs {
  background-color: #0d3c61 !important;
  /* background-color: #4d728c!important; */
}

.my-bg-primary {
  background-color: #0d3c61 !important;
}

/* .globalnav-bg{
  padding: 0.5%;
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure a higher z-index than the rest of the content */
}

.modal-container {
  position: relative;
  background: rgb(233, 226, 226);
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1001; /* Make sure it's higher than the overlay */
}

/* .close-button {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  font-size: 14px;
  color: #f3eeee;
  padding: 6px; 
  border-radius: 10%; 
} */

@media (max-width: 800px) {
  .close-button {
    right: 0; /* Adjust the position for smaller screens */
  }
}

.modal-image {
  max-width: 100%;
  max-height: 80vh; /* Adjust the percentage as needed */
  margin-top: 10px;
}
